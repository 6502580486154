
import { defineComponent, ref, reactive, onMounted, computed, watch } from 'vue'
import Input from '@/components/UI/Input.vue'
import InputElement from '@/components/UI/Input.vue'
import ListingTable from '@/components/UI/ListingTable.vue'
import Pagination from '@/components/UI/Pagination.vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import Loader from '@/components/UI/Loader.vue'
import { useStore } from '@/store'
import InfoPopup from '@/components/UI/InfoPopup.vue'
import { ordersActionTypes } from '@/store/orders/actions'
import { MutationTypes } from '@/store/orders/mutations'
import Button from '@/components/UI/Button.vue'
export default defineComponent({
  name: 'Home',
  setup() {
    const searchBy = ref('')
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const totalOrders = computed(() => store.state.orders.totalOrders)
    const loader = computed(() => store.state.orders.loader)
    const getAllOrders = computed(() => store.state.orders.allOrders)
    const dowloadError = computed(() => store.state.orders.dowloadOrdersError)
    const firstDate = ref('')
    const lastDate = ref('')
    const initializeRequest = async (query) => {
      await store.dispatch(ordersActionTypes.GET_ALLORDERS, { query: query })
    }
    onMounted(async () => {
      initializeRequest(route.query)
      if (route.query.startDate) {
        firstDate.value = route.query.startDate as string
      }
      if (route.query.endDate) {
        lastDate.value = route.query.endDate as string
      }
    })
    watch(
      () => route.query,
      () => {
        if (route.name == 'Orders') {
          initializeRequest(route.query)
        }
      }
    )
    const onInput = (value) => {
      searchBy.value = value
    }
    const onEnter = () => {
      const searchedTerm = (searchBy.value as string).trim().toLowerCase()
      const query: { query?: string } = Object.assign({}, route.query, { query: searchedTerm, page: 1 })
      if (!searchedTerm) {
        delete query.query
      }
      router.push({ query })
    }
    const ordersHeadings = reactive([
      { label: 'Company name', key: 'name' },
      { label: 'Address', key: 'address' },
      { label: 'Postcal code', key: 'postalCode' },
      { label: 'City', key: 'city' },
      { label: 'Ordered Cars', key: 'carOrders' }
    ])
    const setPage = (pageNumber) => {
      const query = Object.assign({}, route.query, { page: pageNumber })
      router.push({ query })
    }
    const sortBy = (value) => {
      const query = Object.assign({}, route.query, { orderBy: value['orderBy'], order: value['order'] })
      router.push({ query })
    }
    const selectStartDate = (value) => {
      firstDate.value = value
      const query = Object.assign({}, route.query, { startDate: value })
      router.push({ query })
    }
    const selectEndDate = (value) => {
      lastDate.value = value
      const query = Object.assign({}, route.query, { endDate: value })
      router.push({ query })
    }
    const downloadOrder = async () => {
      await store.dispatch(ordersActionTypes.DOWNLOAD_ALLORDERS, {
        lang: localStorage.getItem('locale') || 'nl',
        query: { startDate: firstDate.value, endDate: lastDate.value }
      })
    }
    const closeInfoPopup = () => {
      store.commit(MutationTypes.SET_ORDER_DOWNLOAD_ERROR, false)
    }
    return {
      searchBy,
      onInput,
      onEnter,
      loader,
      totalOrders,
      ordersHeadings,
      getAllOrders,
      route,
      setPage,
      sortBy,
      firstDate,
      lastDate,
      selectStartDate,
      selectEndDate,
      downloadOrder,
      closeInfoPopup,
      dowloadError
    }
  },
  components: {
    Input,
    Button,
    InputElement,
    ListingTable,
    Loader,
    Pagination,
    InfoPopup
  }
})
